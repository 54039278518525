body, pre {
  font-family: 'Avenir','Helvetica Neue','Helvetica','Arial', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro','メイリオ', 'Meiryo', '游ゴシック', 'Yu Gothic', 'ＭＳ Ｐゴシック', sans-serif;
}

.main {
  overflow-y: auto;
  height: auto;
}

@media (min-width: 576px) {
  .main {
    overflow-y: scroll;
    height: calc(100vh - 1.8vw - 1.65rem - 0.5rem - 8px - 24px - 16px - 16px - 78px - 76px);
  }
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
}

.triangle-top {
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #007bff transparent;
}

.triangle-top-right {
  border-width: 0 7px 7px 0;
  border-color: transparent #007bff transparent transparent;
}

.triangle-right { 
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent #007bff;
}

.triangle-bottom-right {
  border-width: 0 0 7px 7px;
  border-color: transparent transparent #007bff transparent;
}

.triangle-bottom {
  border-width: 5px 5px 0 5px;
  border-color: #007bff transparent transparent transparent;
}

.triangle-bottom-left {
  border-width: 7px 0 0 7px;
  border-color: transparent transparent transparent #007bff;
}

.triangle-left { 
  border-width: 5px 5px 5px 0;
  border-color: transparent #007bff transparent transparent;
}

.triangle-top-left {
  border-width: 7px 7px 0 0;
  border-color: #007bff transparent transparent transparent;
}